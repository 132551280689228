import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { Free } from '~/constants/tiers'
import useAuth from '~/hooks/useAuth'
import { getTier } from '~/selectors/auth'
import ClassActions from './widgets/ClassActions'
import CompanySelect from './widgets/CompanySelect'
import Connections from './widgets/Connections'
import Content from './widgets/Content'
import MyCampaigns from './widgets/MyCampaigns'
import NewCompanies from './widgets/NewCompanies'
import NewFunds from './widgets/NewFunds'
import NewMandates from './widgets/NewMandates'
import NewPeople from './widgets/NewPeople'
import Presentations from './widgets/Presentations'
import QuickLinks from './widgets/QuickLinks'
import Subscriptions from './widgets/Subscriptions'
import UpcomingEvents from './widgets/UpcomingEvents'
import UpcomingMeetings from './widgets/UpcomingMeetings'

const Dashboard = () => {
  const { Track } = useTracking({ page: 'Dashboard' })

  const { role } = useAuth()
  const tier = useSelector(getTier)

  const { subscriptionDashboardWidget, showPresentations } = useFlags()

  return (
    <Track>
      <div className="p-4 md:p-0 md:-m-8 lg:bg-grey-50">
        <div className="flex flex-col-reverse md:space-y-8 lg:space-y-0 lg:flex-row w-full">
          <div className="w-full space-y-8 md:px-8 lg:p-8">
            <UpcomingEvents />
            <MyCampaigns />
            <NewMandates />
            {role !== 'Manager' && <NewFunds />}
            <Connections />
            <div className="flex flex-col xl:grid xl:grid-cols-2 gap-8 xl:gap-4">
              <NewPeople />
              <NewCompanies />
            </div>
            <ClassActions />
            <Content />
            {showPresentations && <Presentations />}
          </div>
          <div className="w-full lg:max-w-sm lg:flex-shrink-0 2xl:max-w-md space-y-8 md:px-8 pb-8 lg:pt-8 lg:bg-grey-100 border-l border-grey-200">
            <div className="p-4 shadow-0 bg-white rounded-big">
              <CompanySelect />
            </div>
            {(role === 'Manager' || role === 'Allocator') && <QuickLinks />}
            <UpcomingMeetings />
            {subscriptionDashboardWidget && tier === Free && <Subscriptions />}
          </div>
        </div>
      </div>
    </Track>
  )
}

export default Dashboard
